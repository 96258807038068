import {mapGetters} from "vuex";
import templateTypes from "@/domain/Entities/Template/templateTypes";
import {
    COMPLETED,
    FULLY_EXECUTED,
    PENDING_APPROVAL,
    PENDING_EXTERNAL_SIGNATURE,
    PENDING_EXTERNAL_SIGNATURE_GC_VIEW,
    PENDING_EXTERNAL_SIGNATURE_SUB_VIEW,
    PENDING_INTERNAL_SIGNATURE,
    PENDING_INTERNAL_SIGNATURE_SUB_VIEW,
    PENDING_SUBMISSION
} from "@/domain/Entities/Task/taskStatuses";

export default {
    computed: {
        ...mapGetters(['user']),
        isTaskSubContractor() {
            return this.user.company.id === this.task.external_company.id;
        },
        isTaskGeneralContractor() {
            return this.user.company.id !== this.task.external_company.id;
        },
        isTaskExternalAssignee() {
            return this.user.id === this.task.external_assignee_id;
        },
        isTaskExternalSigner() {
            return this.user.id === this.task.external_signer_id;
        },
        isTaskInternalAssignee() {
            return this.user.id === this.task.internal_assignee_id;
        },
        isTaskInternalSigner() {
            return this.user.id === this.task.internal_signer_id;
        },
        isContract() {
            return this.task.type === templateTypes.CONTRACT_TEMPLATE_TYPE;
        },
        isAcknowledge() {
            return this.task.type === templateTypes.ACKNOWLEDGE_TEMPLATE_TYPE;
        },
        isRequest() {
            return this.task.type === templateTypes.REQUEST_DOCUMENT_TEMPLATE_TYPE;
        },
        isPendingExternalSignature() {
            return this.task.status === PENDING_EXTERNAL_SIGNATURE;
        },
        isPendingInternalSignature() {
            return this.task.status === PENDING_INTERNAL_SIGNATURE;
        },
        isPendingSignature() {
            return this.isPendingExternalSignature || this.isPendingInternalSignature;
        },
        isPendingSubmission() {
            return this.task.status === PENDING_SUBMISSION;
        },
        isPendingApproval() {
            return this.task.status === PENDING_APPROVAL;
        },
        isFullyExecuted() {
            return this.task.status === FULLY_EXECUTED;
        },
        isCompleted() {
            return this.task.status === COMPLETED;
        },
        statusDescription() {
            if (this.isPendingExternalSignature)
                return this.isTaskSubContractor
                    ? PENDING_EXTERNAL_SIGNATURE_SUB_VIEW
                    : PENDING_EXTERNAL_SIGNATURE_GC_VIEW;

            if (this.isPendingInternalSignature && this.isTaskSubContractor)
                return PENDING_INTERNAL_SIGNATURE_SUB_VIEW;

            return this.task.status;
        }
    }
}
